export function logAmplitudeEvent(event) {
  if (!window.amplitudeInstance) return Promise.resolve();
  return new Promise((resolve, reject) => {
    window.amplitudeInstance.logEvent(event.name, event.props, resolve, reject);
  });
}

export function logEvent(event) {
  if (window.gon?.zencityUserId) {
    logAmplitudeEvent(event);
    window.plantrack?.track?.(event.name, { info: event.props });
  }
}
